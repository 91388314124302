import type { NavigationCategoryResponse } from '~/composables/types/api/searchDiscover/header'
import handleError from '~/composables/api/handleResponse'
import type { ApiResponse } from '~/composables/types/api/apiResponse'
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination'

export default async function getNavigation(param: string): Promise<NavigationCategoryResponse | ApiResponse | []> {
    const url = `/navigations/${param}`
    const { fetchSdApi } = fetchOrderDetermination()

    try {
        const response = (await fetchSdApi(url)) as NavigationCategoryResponse
        if (response.error) {
            return handleError<NavigationCategoryResponse>(response)
        }

        return response.data.attributes.isActive ? response : []
    } catch (error) {
        return handleError<NavigationCategoryResponse>(error)
    }
}
